import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import iconOne from "../Images/fig1.png";
import iconTwo from "../Images/fig2.png";
import iconThree from "../Images/fig3.png";
import iconFour from "../Images/fig4.png";
import iconFive from "../Images/fig5.png";
import iconSix from "../Images/fig6.png";

export default function EcoSystem() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
          className="common-gap p-b-0"
        >
          <Typography
            mt={15}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "24px" : "36px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              letterSpacing: "normal",
              marginTop: "0px",
              paddingLeft: "0px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            Retik
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              Ecosystem
            </span>
          </Typography>
          <Typography
            mt={2}
            px={!matches && 10}
            sx={{
              fontWeight: 400,
              fontFamily: "'Poppins'",
              maxWidth: matches ? "100%" : "75%",
              lineHeight: "27px",
              color: "#FFFFFF",
              padding: "0px",
              marginTop: "32px",
              margin: "24px auto 0",
              textAlign: "center",
            }}
          >
            A comprehensive DeFi solution, encompassing a non-custodial wallet,
            swap aggregator, DeFi debit cards, payment gateway, AI-powered P2P
            lending, and perpetual futures/options trading for seamless crypto
            management.
          </Typography>
        </Grid>
        <Grid className="card-grid common-gap grid-gap-2">
          <div className="ecosystem-card">
            <img src={iconOne} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">DeFi Debit Cards</h4>
            <p className="card-p-small">
              Prioritize your financial privacy with anonymous transactions,
              eliminating the need for KYC procedures, and ensure security with
              Retik DeFi Debit Cards.
            </p>
          </div>
          <div className="ecosystem-card">
            <img src={iconTwo} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">Payment Gateway</h4>
            <p className="card-p-small">
              Seamlessly accept cryptocurrencies in your online business,
              expanding payment options for your customers.
            </p>
          </div>
          <div className="ecosystem-card">
            <img src={iconThree} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">DeFi Wallet</h4>
            <p className="card-p-small">
              A secure, non-custodial wallet supporting multiple blockchains,
              providing you full control over your assets.
            </p>
          </div>
          <div className="ecosystem-card">
            <img src={iconFour} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">Swap Aggregator</h4>
            <p className="card-p-small">
              Effortlessly find the most favorable rates by instantly comparing
              quotes and fees across various decentralized exchanges (DEXes).
            </p>
          </div>
          <div className="ecosystem-card">
            <img src={iconFive} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">
              AI-Powered P2P Lending
            </h4>
            <p className="card-p-small">
              Let our powerful AI analyze lending options for you, ensuring you
              get the best and most profitable lending solutions.
            </p>
          </div>
          <div className="ecosystem-card">
            <img src={iconSix} alt="" className="m-b-24" />
            <h4 className="h4-title m-b-24 no-opcity">
              Perpetual Futures & Options
            </h4>
            <p className="card-p-small">
              Engage in long, short, call, and put options directly from your
              Retik App, simplifying your trading experience across different
              assets.
            </p>
          </div>
        </Grid>
      </Container>
    </>
  );
}
