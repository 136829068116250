import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import token from "../Images/giveaway.png";

export default function GiveawayCta() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          display="flex"
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
          className="common-gap p-t-0"
        >
          <div className="giveWay-flex">
            <div className="giveWay-Left">
              <h3>Join The Giveaway!</h3>
              <p>
                Ready to Win Big? Enter the Massive $333k Retik Finance
                Giveaway! Participate and complete all the entries for your shot
                at claiming the grand prize. Don't miss out on your chance to be
                the lucky winner!
              </p>
              <a href="" className="giveaway-btn">Join The Giveaway</a>
            </div>
            <div>
              <img src={token} width="100%" alt="" />
            </div>
          </div>
        </Grid>
      </Container>
    </>
  );
}
