import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import imageOne from "../Images/img2.png";
import imageTwo from "../Images/img1.png";
import imageThree from "../Images/img10.png";
import imageFour from "../Images/img3.png";
import imageFive from "../Images/img4.png";
import image6 from "../Images/img6.png";
import image7 from "../Images/img7.png";
import image8 from "../Images/img8.png";
import image9 from "../Images/img9.png";
import image10 from "../Images/img10.png";
import image11 from "../Images/img11.png";
import image12 from "../Images/img12.png";
import image13 from "../Images/img13.png";
import image14 from "../Images/img14.png";
import image15 from "../Images/img15.png";
import image16 from "../Images/img16.png";
import image17 from "../Images/img17.png";
import image18 from "../Images/img18.png";
import image19 from "../Images/img19.png";
import image20 from "../Images/img20.png";
import image21 from "../Images/img21.png";


export default function FeatureSlider() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl" className="text-center">
        <h4 className="feature-title">Featured In</h4>
        <marquee>
          <div className="feature-slider">
            <img src={imageOne} alt="" />
            <img src={imageTwo} alt="" />
            <img src={imageThree} alt="" />
            <img src={imageFour} alt="" />
            <img src={imageFive} alt="" />
            <img src={image6} alt="" />
            <img src={image7} alt="" />
            <img src={image8} alt="" />
            <img src={image9} alt="" />
            <img src={image10} alt="" />
            <img src={image11} alt="" />
            <img src={image12} alt="" />
            <img src={image13} alt="" />
            <img src={image14} alt="" />
            <img src={image15} alt="" />
            <img src={image16} alt="" />
            <img src={image17} alt="" />
            <img src={image18} alt="" />
            <img src={image19} alt="" />
            <img src={image20} alt="" />
            <img src={image21} alt="" />
          </div>
        </marquee>
      </Container>
    </>
  );
}
