import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import token from "../Images/image5.png";

export default function RealWorld() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl" className="hidden-xs">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          display="flex"
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
          className="common-gap"
        >
          <Grid item md={6} xs={12} pb={matches && 2}>
            <img src={token} width="100%" alt="" />
          </Grid>
          <Grid item md={6} xs={12} pb={matches && 2}>
            <Typography
              mt={15}
              sx={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: matches ? "30px" : "36px",
                lineHeight: "122.2%",
                color: "#FFFFFF",
                letterSpacing: "normal",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  background: "transparent",
                  backgroundImage:
                    "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Real-World{" "}
              </span>
              Crypto Adoption
            </Typography>
            <Typography
              mt={2}
              px={!matches && 10}
              sx={{
                fontWeight: 400,
                fontFamily: "'Poppins'",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
                padding: "0px",
                marginTop: "32px",
              }}
            >
             Retik Finance is committed to bridging the gap between the crypto realm and real-life utility. Our mission extends beyond digital transactions, as we believe in bringing cryptocurrency to the forefront of everyday experiences. We understand that true adoption goes beyond the virtual realm, and that's why we're pioneering real-life integration like never before.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
