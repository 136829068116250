import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function DetailCta() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          display="flex"
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
           className="p-t-0 p-b-30"
          justifyContent={"center"}
        >
          <div className="detail-bg">
            <h3 className="detail-title">Retik in detail</h3>
            <p className="detail-content">
              Below are several significant documents that provide an in-depth
              explanation of the Retik Ecosystem.
            </p>
            <div className="detail-flex">
              <a href="https://retik.com/audit/" className="detail-btn-one">Audit</a>
              <a href="https://retik.com/whitepaper.pdf" className="detail-btn-one detail-btn-three">Whitepaper</a>
            </div>
          </div>
        </Grid>
      </Container>
    </>
  );
}
