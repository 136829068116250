import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import token from "../Images/image4.png";

export default function Rewards() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
          className="common-gap"
        >
          <Grid item md={6} xs={12} pb={matches && 2}>
            <Typography
              mt={15}
              sx={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: matches ? "24px" : "30px",
                lineHeight: "122.2%",
                color: "#FFFFFF",
                letterSpacing: "normal",
                marginTop: "0px",
                paddingLeft: "0px",
              }}
              className="xs-m-b-20px"
            >
              Rewards and Tier {" "}
              <span
                style={{
                  background: "transparent",
                  backgroundImage:
                    "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                System
              </span>
            </Typography>
            <Grid
            item
            md={6}
            xs={12}
            pb={matches && 2}
            justifyContent={"center"}
            display={"flex"}
          >
            <img src={token} alt="" className="full-width-xs visible-xs"/>
          </Grid>
            <Typography
              mt={2}
              px={!matches && 10}
              sx={{
                fontWeight: 400,
                fontFamily: "'Poppins'",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
                padding: "0px",
                marginTop: "32px",
              }}
            >
              Experience unprecedented rewards with Retik's DeFi debit cards –
              an embodiment of financial freedom. Within our tier system –
              Silver, Gold, and Diamond – each transaction fuels your journey
              with increasing cashback benefits. Silver cardholders receive 2.5%
              cashback, while Gold offers 3.75% and Diamond leads with 5%.
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            pb={matches && 2}
            justifyContent={"center"}
            display={"flex"}
          >
            <img src={token} alt="" className="full-width-xs hidden-xs"/>
          </Grid>
        </Grid>
        <Grid className="card-grid common-gap">
          <div className="silver-card">
            <h4 className="h4-title">Silver</h4>
            <p className="card-p-24">2.5% Cashback</p>
            <p className="card-p-small">Card cost: $10</p>
          </div>
          <div className="silver-card card-secondary">
            <h4 className="h4-title card-secondary-color">Gold</h4>
            <p className="card-p-24">3.75% Cashback</p>
            <p className="card-p-small">Card cost: $49</p>
          </div>
          <div className="silver-card">
            <h4 className="h4-title card-multi-color">Diamond</h4>
            <p className="card-p-24">5% Cashback</p>
            <p className="card-p-small">Card cost: $99</p>
          </div>
        </Grid>
      </Container>
    </>
  );
}
