import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import token from "../Images/image1.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function DebitCard() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 10 }}
          display="flex"
          px={!matches && 3}
          py={matches ? 5 : 6}
          alignItems={"center"}
          paddingBottom={ matches ? "0px" : "40px"}
          paddingTop={ matches ? "0px" : "80px"}
        >
          <Grid item md={6} xs={12} pb={matches && 2}>
          <Typography
              mt={15}
              sx={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: matches ? "24px" : "30px",
                lineHeight: "122.2%",
                color: "#FFFFFF",
                letterSpacing: "normal",
                marginTop: matches ? "30px" : "0px",
              }}
              className="visible-xs xs-m-b-20px"
            >
              <span
                style={{
                  background: "transparent",
                  background: "linear-gradient(80.03deg, #6dd8d4 14.6%, #f389c0 32.28%), linear-gradient(0deg, #fff, #fff)",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: matches ? "24px" : "30px",
                }}
              >
                Retik{" "}
              </span>
              DeFi Crypto Debit Cards
            </Typography>
            <img src={token} width="100%" alt="" />
          </Grid>
          <Grid item md={6} xs={12} pb={matches && 2}>
            <Typography
              mt={15}
              sx={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: matches ? "24px" : "30px",
                lineHeight: "122.2%",
                color: "#FFFFFF",
                letterSpacing: "normal",
                marginTop: matches ? "30px" : "0px",
              }}
              className="hidden-xs"
            >
              <span
                style={{
                  background: "transparent",
                  background: "linear-gradient(80.03deg, #6dd8d4 14.6%, #f389c0 32.28%), linear-gradient(0deg, #fff, #fff)",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontSize: matches ? "24px" : "30px",
                }}
              >
                Retik{" "}
              </span>
              DeFi Crypto Debit Cards
            </Typography>
            <Typography
              mt={2}
              px={!matches && 10}
              sx={{
                fontWeight: 400,
                fontFamily: "'Poppins'",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
                padding: "0px",
                marginTop: "32px",
              }}
            >
              Retik DeFi Debit Cards redefine financial accessibility and
              security. Offering anonymous transactions without the need for
              KYC, prioritizing user privacy.
              <br /> Retik DeFi Debit Cards offer a range of benefits,
              including:
            </Typography>
            <Typography
              mt={2}
              px={!matches && 10}
              sx={{
                fontWeight: 400,
                fontFamily: "'Poppins'",
                fontSize: "16px",
                color: "#FFFFFF",
                padding: "0px",
                marginTop: "32px",
              }}
            >
              <ul style={{ paddingLeft: "15px" }}>
                <li>
                  <span className="card-li">Privacy:</span> Anonymous
                  transactions without KYC.
                </li>
                <li>
                  <span className="card-li">Daily Convenience:</span> Perfect
                  for daily transactions, online and offline, from shopping to
                  dining.
                </li>
                <li>
                  <span className="card-li">Global Accessibility:</span> Use
                  them across borders, whether you're at a local store or
                  traveling abroad.
                </li>
                <li>
                  <span className="card-li">Transaction Efficiency:</span> Swift
                  and secure transactions, enhancing the overall payment
                  experience.
                </li>
                <li>
                  <span className="card-li">ATM Accessibility:</span> Withdraw
                  cash anywhere in the world.
                </li>
                <li>
                  <span className="card-li">Security:</span> Enjoy anonymous
                  transactions while keeping your funds secure in Retik's non
                  custodial DeFi wallet.
                </li>
                <li>
                  <span className="card-li">Rewards Program:</span> Earn rewards
                  with every use, turning spending into savings.
                </li>
                <li>
                  <span className="card-li">Airport Lounge Access:</span> Enjoy
                  complimentary airport lounge access worldwide.
                </li>
              </ul>
            </Typography>
            <a href="https://retikcards.com/" target="_blank" className="custom-btn">Get Retik Card</a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
